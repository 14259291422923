import PropTypes from "prop-types"
import styled from "styled-components"
import React from "react"
import Image from "gatsby-image"

const ServiceWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: ${props => props.$even ? 'row' : 'row-reverse'};;
  }
`

const ImageWrapper = styled.div`
  margin: 0 0 1rem 0;

`

const TextWrapper = styled.div`
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1500px) {
    flex-basis: 200%;
  }

`

const H2 = styled.h2`
  padding-bottom: 1rem;
  text-align: center;
`

const H4 = styled.h4`
text-align: center;
margin-bottom: 2rem;
`

const Blurb = styled.p`
  padding-bottom: 1rem;
`

const AllTextWrapper = styled.div`
justify-content: space-evenly;
display: flex;
flex-direction: column;
row-gap: 1rem;
`

const Service = ({ title, serviceItems, image, text, even }) => {
  return (
    <ServiceWrapper $even={even}>
      <AllTextWrapper>
      <H2>{title}</H2>
      <H4>{serviceItems.join(' | ')}</H4>
      <TextWrapper>
        <Blurb>{text}</Blurb>
      </TextWrapper>
      </AllTextWrapper>
      <ImageWrapper>
        <Image durationFadeIn="0" fixed={image.fixed} />
      </ImageWrapper>
    </ServiceWrapper>
  )
}

Service.defaultProps = {
  title: ``,
  text: ``,
  image: ``
}

Service.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.object
}

export default Service
