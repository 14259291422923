import React from "react"
import Hero from "../components/Hero/Hero"
import { graphql } from "gatsby"
import styled from "styled-components"

import Divider from "../components/Divider/Divider"
import FeatureSection from "../components/FeatureSection/FeatureSection"
import Main from "../components/main"
import SEO from "../components/seo"
import Service from "../components/Service"

export const query = graphql`
{
  companyYaml {
    short_description
    grants
    grants_explainer
  }
  aboutImageMWeb: file(relativePath: { eq: "about_bubble_mweb.png" }) {
    childImageSharp {
      fluid(maxWidth: 300, quality: 100) {
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      }
    }
  }
  aboutImageDesktop: file(relativePath: { eq: "about_bubble_desktop.png" }) {
    childImageSharp {
      fluid(maxWidth: 300, quality: 100) {
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      }
    }
  }
  services: allServicesYaml(sort: {fields: priority}) {
    edges {
      node {
        service_items
        title
        description
        logo {
          childImageSharp {
            fixed(width: 200) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
}
`

const GrantListWrapper = styled.div`
  padding: 2rem 2rem;
  @media (min-width: 768px) {
    padding: 2rem 4rem;
  }
`

const ServicesWrapper = styled.div`
display: flex;
align-items: stretch;
flex-wrap: wrap;
flex-direction: column;
row-gap: 8rem;
`

const H3 = styled.h3`
  padding: 0 0 1rem 0;
`

const LI = styled.li`
  list-style: disclosure-closed;
`

const GrantList = ({ title, grants }) => {
  const grantElements = grants.map((grant, idx) => {
    return <LI key={idx}>{grant}</LI>
  })
  return (
    <GrantListWrapper>
      <H3>{title}</H3>
      <ul>
      {grantElements}
      </ul>
    </GrantListWrapper>
  )
}


const ServicesPage = ({ data }) => {

  const services = data.services.edges.map((el, idx) => {
    const service = el.node
    return <Service key={idx} title={service.title} serviceItems={service.service_items} text={service.description} image={service.logo.childImageSharp} even={idx % 2} />
  })

  return (
    <>
      <SEO title="Services" />
      <Hero title="Our Services" subtitle="" backgroundImageName="services" />
      <Main>
      <FeatureSection
        mwebImage={data.aboutImageMWeb.childImageSharp.fluid}
        desktopImage={data.aboutImageDesktop.childImageSharp.fluid}
        text={data.companyYaml.short_description}
        />
      <Divider />
      <ServicesWrapper>
      {services}
      </ServicesWrapper>
      <Divider />
      <GrantList title={data.companyYaml.grants_explainer} grants={data.companyYaml.grants} />
      </Main>
    </>
  )
}

export default ServicesPage
